<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header ">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                    <img width="24" height="24" src="@/assets/icon/uide/x.svg" />
                </a>
            </div>
            <span class="fL nowrap">輸入店家序號</span>
            <div class="flexH width right">


            </div>
        </section>
        <!-- Main -->
        <section class="main">

            <div class=" flexV width padding mt-4 mb-4">

                <div class="flex gap-4 flex-col  ">
                    <div class="py-3 px-4  gap-4 rounded bg-white flex">
                        <div class="flex items-center gap-1">
                            <img  width="14"  height="14" src="@/assets/icon/uide/qrcodeS.svg"/>
                            <div class="font-medium cNeutral500">店家序號<span class="red">*</span></div>
                        </div>
                        <input
                            type="text"
                            class="pcNeutra300 "
                            placeholder="請填寫"
                                                        
                        />
                    </div>
                    <div class="py-3 px-4  gap-4 rounded bg-white flex flex-col">
                        <div class="font-medium cNeutra900">序號圖例說明</div>
                        <img  class="w-full" src="@/assets/icon/uide/Frame1210.svg"/>
                        <div class="cNeutra900">店家序號通常都設置在店家 QR Code 下方，序號共10碼，如上圖店家序號範例為 /Ua85735w2。</div>
                    </div>
               

                </div>
                <a class="uide-button-1 mt-8">確定</a>




            </div>
        </section>
    
    <PopupV2
      :open="false"
      :title="'兌換成功'"
      :message="'兌換雲林幣成功，獲得 [Num] 枚。感謝您參與 [活動名稱]！'"
      :footer="true"
      :imgType="'success'"
      :buttonLeft="'前往雲林幣錢包'"
      :buttonRight="'確認'"
      
    />

    <PopupV2
      :open="false"
      :title="'提示'"
      :message="'已領取過獎勵囉。'"
      :footer="true"      
      :buttonLeftShow="false"    
      :buttonRight="'確認'"
      
    />
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";
import PopupV2 from "@/components/PopupV2";



export default {
    name: "NotificationSetting",
    components: {
        PopupV2
    },
    data() {
        return {
            data: [],
            statusNames: {
                0: '快來搶獎勵',
                1: '已領取獎勵',
                2: '獎勵已搶完'
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        console.log("mounted!");
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('pageData', this.pageData);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        pageData() {

            let pageData = {
                img: '/img.png',
                title: '拉近你我距離的縣民 App，服務上線滿意度調查問卷',
                activity_time: '調查時間：2024/06/05 ~ 2023/07/04',
                activity_desc: 'Lorem ipsum dolor sit amet consectetur. Scelerisque et vel nisl et massa nibh amet ut. Id volutpat leo lobortis dapibus. Sollicitudin pulvinar scelerisque posuere dolor.',
                point: 5,
            };

            return pageData;
        }
    },
    methods: {
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
                .doc(this.user.user.firestoreChannelId)
                .get()
                .then(snapshot => {
                    if (!snapshot.exists) return;
                    this.latestReadAt = snapshot.data().latestReadAt;
                });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        },
        getStatusName(status) {
            return this.statusNames[status] || 'Unknown';
        },
    }
};
</script>

