<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header notice">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.go(-1)">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">通知設定</span>
            <div class="flexH width right">
                <!-- NOTE: Howard's fix: 暫時隱藏全部已讀功能 -->
                <!-- <img src="@/assets/icon/setting-v2.svg" /> -->
                            
            </div>
        </section>
        <!-- Main -->
        <section class="main ">
            
            <div class=" flexV width padding bg-white mt-4 mb-4">
                <div class="board-pad">
                    <div class=" flexH   relative width ">                      
                        <div class="flexH width gap-1 pt-12p pb-12p  justify-between">
                            <div>政令通知</div>
                            <div><ToggleSwitch v-model="policy" /></div>
                        </div>                       
                    </div>
                    <div class="lineH"></div>

                    
                    <div class=" flexH   relative width ">                      
                        <div class="flexH width gap-1 pt-12p pb-12p  justify-between">
                            <div>活動通知</div>
                            <div><ToggleSwitch v-model="activity" /></div>
                        </div>                       
                    </div>
                    <div class="lineH"></div>

                    <div class=" flexH   relative width ">                      
                        <div class="flexH width gap-1 pt-12p pb-12p  justify-between">
                            <div>金幣歸戶通知</div>
                            <div><ToggleSwitch v-model="addCoin" /></div>
                        </div>                       
                    </div>
                    <div class="lineH"></div>
                    <div class=" flexH   relative width ">                      
                        <div class="flexH width gap-1 pt-12p pb-12p  justify-between">
                            <div>金幣使用通知</div>
                            <div><ToggleSwitch v-model="useCoin" /></div>
                        </div>                       
                    </div>
                    <div class="lineH"></div>

                    <div class=" flexH   relative width ">                      
                        <div class="flexH width gap-1 pt-12p pb-12p  justify-between">
                            <div>系統通知</div>
                            <div><ToggleSwitch v-model="system" /></div>
                        </div>                       
                    </div>                    

                </div>
              
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";
import ToggleSwitch from '../components/ToggleSwitch.vue';

export default {
    name: "NotificationSetting",
    components: {
        ToggleSwitch
    },
    data() {
        return {
            // notifications: [],
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            latestReadAt: 0,
            policy:true,
            activity:true,
            addCoin:true,
            useCoin:true,
            system:true,
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    watch: {
        notifySettings() {
            this.setToggle()
        }
    },
    mounted() {
        console.log("mounted!");
        this.getToggle()
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('Notifications', this.notifications);
        }, 4000)
    },
    computed: {
        ...mapState(["user", "userInfo"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        notifications() {
            let ns = this.$store.getters['noti/notifications'];
            // fake data
            // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
            return Object.values(ns).sort((a, b) => {
                return b.ts - a.ts;
            });
        },
        notifySettings() {
            return [this.policy, this.activity, this.addCoin, this.useCoin, this.system];
        }
    },
    methods: {
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        },
        getToggle() {
            this.$http({
                url: `${this.apiHost}/notifications/v1/merchants/${this.merchantId}/push/setting/${this.userInfo.memberId}/${this.user.userId}`,
                method: 'GET',
            }).then(function (response) {
                console.log(JSON.stringify(response.data));
                return response.data;
            }).then(data => {
                const {activity, add_coin, policy, system, use_coin} = data.data
                this.policy = policy
                this.activity = activity
                this.addCoin = add_coin
                this.useCoin = use_coin
                this.system = system
            })
        },
        setToggle() {
            this.$http({
                url: `${this.apiHost}/notifications/v1/merchants/${this.merchantId}/push/setting`,
                method: 'POST',
                data: {
                    "member_id": this.userInfo.memberId,
                    "uuid": this.user.uuid,
                    "policy":this.policy,
                    "activity":this.activity,
                    "add_coin":this.addCoin,
                    "use_coin":this.useCoin,
                    "system":this.system    
                }
            })
        }
    }
};
</script>

