<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header ">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">活動資訊</span>
            <div class="flexH width right" >
               
                            
            </div> 
        </section>
        <!-- Main -->
        <section class="main ">
            
            <div class=" flexV width padding mt-4 mb-4">
                <div
                    class="text-center flex gap-6 item-center py-4 flex-col"
                    v-if="(!pageList || pageList.length == 0)  == true"                    
                >
                    <img  width="160" height="160" src="@/assets/icon/page-empty-content.svg" />
                   <div class="fL font-medium  cNeutral500 text-center">很抱歉，目前沒有相關活動內容</div>
                </div>
                <div v-else class="flex gap-4 flex-col" >
                    <div
                        v-for="(row, index) in pageList"
                        :key="'row' + index"
                        @click="$router.push('/b2-detail/' + row.id)"
                    >
                        <div class="uide-mission-card">
                            <div class="flex gap-2 items-center">
                                <img class=rounded-base  width="72px" height="72px" src="@/assets/icon/uide/_b2-img1.png"/>                                
                                <div class="flex gap-1 flex-col">
                                    <div class="font-medium cNeutral900  text-xl">{{ row.title }}</div>
                                    <div class=" cNeutral400  text-sm">{{ row.startTs.split(' ')[0] }}</div>
                                </div>
                            </div>

                            <!-- <div class=" cNeutral900 ellipsis-single-line ">活動時間：{{ row.startTs }} ~ {{ row.endTs }}</div> -->
                            <div class=" cNeutral900  ellipsis-two-line ">{{ parseContent(row.htmlContent) }}</div>
                        </div>
                    </div>
                    
                 
                </div>
              
               
                
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";



export default {
    name: "b2",
    components: {
        
    },
    data() {
        return {
            data: [],
            statusNames: {
                0: '快來搶獎勵',
                1: '已領取獎勵',
                2: '獎勵已搶完'
            },
            pageList: [],
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     // called before the route that renders this component is confirmed.
    //     // does NOT have access to `this` component instance,
    //     // because it has not been created yet when this guard is called!
    //     console.log(to, from);
    //     next((vm) => {
    //         let that = vm;
    //         if (that.user && that.user.userId) {
    //             //
    //         } else {
    //             // window.location.reload();
    //             next("/home");
    //         }
    //     });
    // },
    mounted() {
        console.log("mounted!");
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });
        this.getArticles()

        setTimeout(() => {
            console.log('pageList', this.pageList);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        // pageList() {

        //     let list = [
        //         {id:1,img:'/img.png',ts:1720961906,title:'定期健檢 健康不減 社區健康篩檢 守護咱的健康',activity_time:"活動時間：113年6月1日 星期六 早上7時至10時",activity_location:"活動地點：古坑鄉永光國小 (地址：雲林縣古坑鄉文昌路84號)"},
        //         {id:2,img:'/img.png',ts:1720961907,title:'113年雲林風箏衝浪海洋深耕永續創生暨親海動無礙',activity_time:"活動時間：113年6月1日 星期六 早上7時至10時",activity_location:"古坑鄉永光國小 (地址：雲林縣古坑鄉文昌路84號)"},
        //         {id:3,img:'/img.png',ts:1720961908,title:'113年6月份免費狂犬病疫苗巡迴注射活動',activity_time:"活動時間：113年6月1日 星期六 早上7時至10時",activity_location:"活動地點：古坑鄉永光國小 (地址：雲林縣古坑鄉文昌路84號)"},

        //     ];            
        //     return Object.values(list).sort((a, b) => {
        //         return b.ts - a.ts;
        //     });
        // }
    },
    methods: {
        parseContent(content) {
            return content.replace(/(<([^>]+)>)/ig, "")
        },
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDateDay(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/yulin/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    member_id: this.userInfo.memberId,
                    uuid: this.user.uuid,
                    message_id: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/notifications/v1/merchants/yunlin/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        }, 
        getStatusName(status) {
            return this.statusNames[status] || 'Unknown';
        },
        callArticleAPI() {
            var config = {
                method: "get",
                // url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/articles?category_type=${this.categoryType}`,
                // url: 'https://shopware.yunlin.citycoins.cc/store-api/core/articles/v1/merchants/yunlin/articles',
                url: `https://dev.portal.yunlin.citycoins.cc/core/articles/v1/merchants/yunlin/articles?category_type=${this.categoryType}`,
                headers: {},
            };

            return this.$http(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
            },
        getArticles() {
            this.callArticleAPI()
                // this.dummyArticle()
            .then((res) => {
                this.pageList = res.data.articles;
            })
            .catch((err) => {
                console.log("err: ", err);
            });
        },
    }
};
</script>

